<template>
  <b-card>
    <b-card-header class="pb-10">
      <b-card-title>How did you learn about the SBI Youth for India?</b-card-title>
      <b-form-checkbox
        checked="false"
        class="custom-control-primary"
        name="check-button"
        switch
        inline
        @change="toggleCitizenShip"
      >
        <p v-if="citizenShip === 'i'">
          All </p>
        <p v-else-if="citizenShip === 'oci'">
          OCI
        </p>
      </b-form-checkbox>
    </b-card-header>
    <VueApexCharts
      height="400"
      type="radar"
      :options="options"
      :series="series[citizenShip]"
    />
  </b-card>
</template>

<script>
import {
  BCardHeader, BCardTitle, BCard, BFormCheckbox,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BCardHeader,
    BCardTitle,
    BCard,
    BFormCheckbox,
    VueApexCharts,
  },
  props: {
    series: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      citizenShip: null,
      options: {
        chart: {
          // width: 500,
          dropShadow: {
            enabled: true,
            blur: 8,
            left: 1,
            top: 1,
            opacity: 0.2,
          },
          offsetY: 5,
        },
        stroke: {
          width: 0,
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
        },
        colors: ['#7266ef', '#04d0e7'],
        plotOptions: {
          radar: {
            // size: 300,
            polygons: {
              // strokeColors: ['#ebe9f1', 'transparent', 'transparent', 'transparent', 'transparent', 'transparent'],
              fill: {
                colors: ['#f8f8f8', '#fff'],
              },
              connectorColors: '#f8f8f8',
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            gradientToColors: ['#dddddd', '#dddddd'],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 3,
          hover: {
            size: 10,
          },
        },
        yaxis: {
          show: true,
        },
        grid: {
          show: false,
          padding: {
            bottom: -27,
          },
        },
        dataLabels: {
          enabled: false,
          background: {
            enabled: true,
            borderRadius: 2,
          },
        },
        xaxis: {
          categories: ['B1', 'B2', 'B3', 'B4', 'B5', 'B6', 'B7', 'B8', 'B9'],
        },
      },
    }
  },

  mounted() {
    this.citizenShip = 'i'
  },

  methods: {
    toggleCitizenShip() {
      this.citizenShip = (this.citizenShip === 'oci') ? 'i' : 'oci'
    },
  },
}
</script>
