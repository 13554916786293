<template>
  <b-card>
    <b-card-header class="pb-10">
      <b-card-title>Academic in each State</b-card-title>
    </b-card-header>
    <VueApexCharts
      height="550"
      type="bar"
      :options="options"
      :series="series[citizenShip]"
    />
  </b-card>
</template>

<script>
import {
  BCardHeader, BCardTitle, BCard,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    // BFormCheckbox,
    VueApexCharts,
  },
  props: {
    series: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      citizenShip: null,
      options: {
        colors: ['#1390FB', '#37E396', '#FCB01A', '#F94460', '#775DD0', '#FBE813', '#B15DD0', '#8CE337', '#F944BB', '#C1D05D', '#D05D5D', '#FF8F1C', '#44E2F9', '#FF7FFA'],
        plotOptions: {
          bar: {
            // columnWidth: '40%',
            backgroundBarRadius: 30,
            borderRadius: 30,
          },
        },
        legend: {
          position: 'top',
          fontSize: '14px',
          horizontalAlign: 'left',
        },
        grid: {
          row: {
            colors: ['#fff', '#f2f2f2'],
          },
          padding: {
            left: 0, // or whatever value that works
            right: 0, // or whatever value that works
          },
        },
        // fill: {
        //   type: 'gradient',
        //   gradient: {
        //     opacityFrom: 0.8,
        //     opacityTo: 1,
        //   },
        // },
        chart: {
          id: 'Academic Each Sate Column Chart',
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        yaxis: {
          max: 18000,
        },
        xaxis: {
          tickPlacement: 'on',
          labels: {
            rotate: -45,
          },
          categories: [
            'Maharashtra',
            'Andhra Pradesh',
            'Uttar Pradesh',
            'West Bengal',
            'Karnataka',
            'Odisha',
            'Bihar',
            'Haryana',
            'Manipur',
            'Madhya Pradesh',
            'Rajasthan',
            'Kerala',
            'Delhi',
            'Assam',
            'Jammu and Kashmir',
            'Tamil Nadu',
            'Jharkhand',
            'Punjab',
            'Uttarakhand',
            'Chhattisgarh',
            'Telangana',
            'Arunachal Pradesh',
            'Dadra and Nagar Haveli and Daman & Diu',
            'Puducherry',
            'Gujarat',
            'Tripura',
            'Nagaland',
            'Chandigarh',
            'Himachal Pradesh',
            'Meghalaya',
          ],
        },
      },
    }
  },

  mounted() {
    this.citizenShip = 'i'
  },

  methods: {
    toggleCitizenShip() {
      this.citizenShip = (this.citizenShip === 'oci') ? 'i' : 'oci'
    },
  },
}
</script>
