<template>
  <b-card>
    <b-card-header class="pb-10">
      <b-card-title>Gender Group</b-card-title>
      <b-form-checkbox
        checked="false"
        class="custom-control-primary"
        name="check-button"
        switch
        inline
        @change="toggleCitizenShip"
      >
        <p v-if="citizenShip === 'i'">
          All </p>
        <p v-else-if="citizenShip === 'oci'">
          OCI
        </p>
      </b-form-checkbox>
    </b-card-header>
    <VueApexCharts
      height="400"
      type="area"
      :options="options"
      :series="series[citizenShip]"
    />
  </b-card>
</template>

<script>
import {
  BCardHeader, BCardTitle, BCard, BFormCheckbox,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    VueApexCharts,
  },
  props: {
    series: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      citizenShip: null,
      options: {
        colors: ['#163AB3', '#C13028', '#000000', '#FFEB1C'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
          width: [3, 3, 4, 2],
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        chart: {
          id: 'Gender Line Chart',
          // animations: {
          //   enabled: true,
          //   easing: 'easeinout',
          //   speed: 800,
          //   animateGradually: {
          //     enabled: true,
          //     delay: 50,
          //   },
          //   dynamicAnimation: {
          //     enabled: true,
          //     speed: 800,
          //   },
          // },
          stacked: false,
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            opacityFrom: 0.01,
            opacityTo: 0.0,
          },
        },
        xaxis: {
          // type: 'datetime',
          // categories: ['03-Mar to 14-Mar', '15-Mar to 26 Mar', '27-Mar to 07-April', '08-April to 19-April', '20-April to 03-May'],
          categories: ['03/03-09/03', '10/03-16/03', '17/03-23/03', '24/03-30/03', '31/03-06/04', '07/04-13/04', '14/04-20/04', '21/04-27/04', '28/04-03/05'],
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
        },
      },
    }
  },

  mounted() {
    this.citizenShip = 'i'
  },

  methods: {
    toggleCitizenShip() {
      this.citizenShip = (this.citizenShip === 'oci') ? 'i' : 'oci'
    },
  },
}
</script>
