<template>
  <b-card>
    <b-card-header class="pb-10">
      <b-card-title>Geographic</b-card-title>
      <b-form-checkbox
        checked="false"
        class="custom-control-primary"
        name="check-button"
        switch
        inline
        @change="toggleCitizenShip"
      >
        <p v-if="citizenShip === 'i'">
          India </p>
        <p v-else-if="citizenShip === 'country'">
          Country
        </p>
      </b-form-checkbox>
    </b-card-header>
    <VueApexCharts
      v-if="series[citizenShip]"
      height="400"
      type="donut"
      :options="options[citizenShip]"
      :series="series[citizenShip].series"
    />
  </b-card>
</template>

<script>
import {
  BCardHeader, BCardTitle, BCard, BFormCheckbox,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BCardHeader,
    BCardTitle,
    BCard,
    BFormCheckbox,
    VueApexCharts,
  },
  props: {
    series: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      citizenShip: null,
      options: {
        i: {
          chart: {
            toolbar: {
              show: true,
            },
          },
          dataLabels: {
            enabled: true,
          },
          legend: { show: false },
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          stroke: { width: 0 },
          labels: [],
          plotOptions: {
            pie: {
              // startAngle: -10,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: '',
                    formatter() {
                      return ''
                    },
                  },
                },
              },
            },
          },
        },
        country: {
          chart: {
            toolbar: {
              show: true,
            },
          },
          dataLabels: {
            enabled: true,
          },
          legend: { show: false },
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          stroke: { width: 0 },
          labels: [],
          plotOptions: {
            pie: {
              // startAngle: -10,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: '',
                    formatter() {
                      return ''
                    },
                  },
                },
              },
            },
          },
        },
      },
    }
  },
  mounted() {
    setTimeout(() => {
      this.citizenShip = 'i'
      this.options[this.citizenShip].labels = this.series[this.citizenShip].labels
    }, 1)
  },
  methods: {
    toggleCitizenShip() {
      if (this.citizenShip === 'country') {
        this.citizenShip = 'i'
        this.options.i.labels = this.series.i.labels
      } else {
        this.citizenShip = 'country'
        this.options.country.labels = this.series.country.labels
        // eslint-disable-next-line prefer-destructuring
        this.options.country.plotOptions.pie.donut.labels.total.label = this.series.country.labels[0]
      }
    },
  },
}
</script>
