<template>
  <section id="dashboard-analytics">
    <!-- <b-row class="match-height">
      <b-col
        lg="6"
        md="12"
      >
        <analytics-congratulation :data="data.congratulations" />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-with-area-chart
          v-if="data.subscribersGained"
          icon="UsersIcon"
          :statistic="kFormatter(data.subscribersGained.analyticsData.subscribers)"
          statistic-title="Subscribers Gained"
          :chart-data="data.subscribersGained.series"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-with-area-chart
          v-if="data.ordersRecevied"
          icon="PackageIcon"
          color="warning"
          :statistic="kFormatter(data.ordersRecevied.analyticsData.orders)"
          statistic-title="Orders Received"
          :chart-data="data.ordersRecevied.series"
        />
      </b-col>
    </b-row> -->

    <b-row class="match-height">
      <b-col lg="12">
        <analytics-academic-state-column-chart :series="series.academic_state" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="12">
        <analytics-age-column-chart :series="series.age_series" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="12">
        <analytics-gender-line-chart :series="series.gender_series" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="6">
        <analytics-academic-streams-radar-chart :series="series.academic_streams_series" />
      </b-col>
      <b-col lg="6">
        <analytics-demographic-donut-chart :series="series.demographic_series" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="6">
        <analytics-apply-fellowship-radar-chart :series="series.apply_fellowship_series" />
      </b-col>
      <b-col lg="6">
        <analytics-how-you-learnt-radar-chart :series="series.how_you_learnt_series" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="12">
        <analytics-work-experience-column-chart :series="series.work_experience_series" />
      </b-col>
    </b-row>

  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
// import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import { kFormatter } from '@core/utils/filter'
// import AnalyticsCongratulation from './AnalyticsCongratulation.vue'
import AnalyticsAgeColumnChart from './AnalyticsAgeColumnChart.vue'
import AnalyticsGenderLineChart from './AnalyticsGenderLineChart.vue'
import AnalyticsWorkExperienceColumnChart from './AnalyticsWorkExperienceColumnChart.vue'
import AnalyticsAcademicStreamsRadarChart from './AnalyticsAcademicStreamsRadarChart.vue'
import AnalyticsApplyFellowshipRadarChart from './AnalyticsApplyFellowshipRadarChart.vue'
import AnalyticsHowYouLearntRadarChart from './AnalyticsHowYouLearntRadarChart.vue'
import json from '../../yfi-data/ChartData.json'
import AnalyticsDemographicDonutChart from './AnalyticsDemographicDonutChart.vue'
import AnalyticsAcademicStateColumnChart from './AnalyticsAcademicStateColumnChart.vue'

export default {
  components: {
    AnalyticsAcademicStateColumnChart,
    AnalyticsDemographicDonutChart,
    AnalyticsHowYouLearntRadarChart,
    AnalyticsApplyFellowshipRadarChart,
    AnalyticsAcademicStreamsRadarChart,
    AnalyticsWorkExperienceColumnChart,
    AnalyticsAgeColumnChart,
    AnalyticsGenderLineChart,
    BRow,
    BCol,
    // AnalyticsCongratulation,
    // StatisticCardWithAreaChart,
  },
  data() {
    return {
      data: {},
      jsonData: json,
      series: {
        age_series: {
          i: [
            {
              name: '11-20',
              data: [0, 0, 0, 0, 0],
            },
            {
              name: '21-30',
              data: [0, 0, 0, 0, 0],
            },
            {
              name: '31-41',
              data: [0, 0, 0, 0, 0],
            },
          ],
          oci: [
            {
              name: '11-20',
              data: [0, 0, 0, 0, 0],
            },
            {
              name: '21-30',
              data: [0, 0, 0, 0, 0],
            },
            {
              name: '31-41',
              data: [0, 0, 0, 0, 0],
            },
          ],
        },
        gender_series: {
          i: [
            {
              name: 'M',
              data: [0, 0, 0, 0, 0],
            },
            {
              name: 'F',
              data: [0, 0, 0, 0, 0],
            },
            {
              name: 'GQ/NB',
              data: [0, 0, 0, 0, 0],
            },
            {
              name: 'DWD',
              data: [0, 0, 0, 0, 0],
            },
          ],
          oci: [
            {
              name: 'M',
              data: [0, 0, 0, 0, 0],
            },
            {
              name: 'F',
              data: [0, 0, 0, 0, 0],
            },
            {
              name: 'GQ/NB',
              data: [0, 0, 0, 0, 0],
            },
            {
              name: 'DWD',
              data: [0, 0, 0, 0, 0],
            },
          ],
        },
        work_experience_series: {
          i: [
            {
              name: 'Less than 1',
              data: [0, 0, 0, 0, 0],
            },
            {
              name: '1-5',
              data: [0, 0, 0, 0, 0],
            },
            {
              name: 'More than 5',
              data: [0, 0, 0, 0, 0],
            },
          ],
          oci: [
            {
              name: 'Less than 1',
              data: [0, 0, 0, 0, 0],
            },
            {
              name: '1-5',
              data: [0, 0, 0, 0, 0],
            },
            {
              name: 'More than 5',
              data: [0, 0, 0, 0, 0],
            },
          ],
        },
        academic_streams_series: {
          i: [
            {
              name: '03-Mar to 15-April',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
            {
              name: '16-April to 03-May',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
          ],
          oci: [
            {
              name: '03-Mar to 15-April',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
            {
              name: '16-April to 03-May',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
          ],
        },
        apply_fellowship_series: {
          i: [
            {
              name: '03-Mar to 15-April',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
            {
              name: '16-April to 03-May',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
          ],
          oci: [
            {
              name: '03-Mar to 15-April',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
            {
              name: '16-April to 03-May',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
          ],
        },
        how_you_learnt_series: {
          i: [
            {
              name: '03-Mar to 15-April',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
            {
              name: '16-April to 03-May',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
          ],
          oci: [
            {
              name: '03-Mar to 15-April',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
            {
              name: '16-April to 03-May',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
          ],
        },
        academic_state: {
          i: [
            {
              name: 'SSH',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
            {
              name: 'Arch',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
            {
              name: 'Engg',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
          ],
        },
        demographic_series: {
          i: {
            labels: [],
            series: [],
          },
          oci: {
            labels: [],
            series: [],
          },
        },
      },
    }
  },
  created() {
    // data
    this.$http.get('/analytics/data')
      .then(response => { this.data = response.data })
  },

  mounted() {
    this.series.age_series = this.jsonData.age_series
    this.series.gender_series = this.jsonData.gender_series
    this.series.work_experience_series = this.jsonData.work_experience_series
    this.series.academic_streams_series = this.jsonData.academic_streams_series
    this.series.apply_fellowship_series = this.jsonData.apply_fellowship_series
    this.series.how_you_learnt_series = this.jsonData.how_you_learnt_series
    this.series.demographic_series = this.jsonData.demographic_series
    this.series.academic_state = this.jsonData.academic_state
  },

  methods: {
    kFormatter,
  },
}
</script>
